<template>
  <c-step
    stepperGrpCd="WO_STEP_CD"
    :stepUnvisibleItems="stepUnvisibleItems"
    :currentStepCd="currentStepCd"
    :param="param"
    v-model="stepCd"
    @stepBystep="stepBystep"
    @closePopup="closePopup"
    @stepClick="stepClick"
  >
  </c-step>
</template>

<script>
export default {
  name: 'work-order-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        workResultId: '',
        plantCd: '',
        woWorkPlanStepCd: '',
        copyFlag: '',
        dailyCheckId: '',
        firstCopy: false,
        count: 0,
        stepCd: '',
        workPlanFlag: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
      currentStepCd: '',
      stepUnvisibleItems: [],
    };
  },
  watch: {
  },
  computed: {
    param() {
      return {
        newEquipmentCd: this.popupParam.newEquipmentCd,
        newTitle:  this.popupParam.newTitle,
        newWoWorkTypeCd:  this.popupParam.newWoWorkTypeCd, // 작업유형 (PM)
        workPlanId: this.popupParam.workPlanId,
        woRequestId: this.popupParam.woRequestId,
        workResultId: this.popupParam.workResultId,
        plantCd: this.popupParam.plantCd,
        stepCd: this.popupParam.stepCd,
        woWorkPlanStepCd: this.popupParam.woWorkPlanStepCd,
        copyFlag: this.popupParam.copyFlag,
        firstCopy: this.popupParam.firstCopy,
        dailyCheckId: this.popupParam.dailyCheckId,
        minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
        workPlanFlag: this.popupParam.workPlanFlag ? this.popupParam.workPlanFlag : false
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.setStep();
    },
    setStep() {
      if (this.popupParam.woWorkPlanStepCd === 'WWPSC00000') {
        this.stepCd = 'WSC0000000'
        this.currentStepCd = 'WSC0000000'
      } else if (this.popupParam.woWorkPlanStepCd === 'WWPSC00001' || this.popupParam.woWorkPlanStepCd === 'WWPSC00002') {
        this.stepCd = 'WSC0000001'
        this.currentStepCd = 'WSC0000001'
      } else if (this.popupParam.woWorkPlanStepCd === 'WWPSC99998' && !this.popupParam.woRequestId) {
        this.stepCd = 'WSC0000001'
        this.currentStepCd = 'WSC0000001'
      } else if (this.popupParam.woWorkPlanStepCd === 'WWPSC99998' && this.popupParam.woRequestId && this.popupParam.copyFlag == 'N') {
        this.stepCd = 'WSC0000000'
        this.currentStepCd = 'WSC0000000'
      } else if (this.popupParam.woWorkPlanStepCd === 'WWPSC99998' && this.popupParam.woRequestId && this.popupParam.copyFlag == 'Y') {
        this.stepCd = 'WSC0000001'
        this.currentStepCd = 'WSC0000001'
      } else if (this.popupParam.woWorkPlanStepCd === 'WWPSC99999') {
        this.stepCd = 'WSC0000002'
        this.currentStepCd = 'WSC0000002'
      } else {
        this.stepCd = 'WSC0000002'
        this.currentStepCd = 'WSC0000002'
      }
      if (!this.popupParam.workPlanId || !this.popupParam.woRequestId) {
        this.stepUnvisibleItems = ['WSC0000000']
      }
    },
    currentStep(step) {
      if (step === 'WWPSC00000' || step === 'WWPSC99999') {
        this.stepCd = 'WSC0000000'
        this.currentStepCd = 'WSC0000000'
      } else if (step === 'WWPSC00001' || step === 'WWPSC00002') {
        this.stepCd = 'WSC0000001'
        this.currentStepCd = 'WSC0000001'
      } else {
        this.stepCd = 'WSC0000002'
        this.currentStepCd = 'WSC0000002'
      }
      this.popupParam.stepCd = this.currentStepCd ;
    },
    closePopup() {
      this.$emit('closePopup')
    },
    stepClick() {
      this.popupParam.count++;
    },
    stepBystep(stepCds) {
      this.popupParam.stepCd = stepCds.stepCd
      this.popupParam.woWorkPlanStepCd = stepCds.woWorkPlanStepCd
      this.currentStep(stepCds.woWorkPlanStepCd)
    },
  }
};
</script>
